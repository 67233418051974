export default theme => ({
	root: {
		'& .MuiButtonBase-root': {
			backgroundColor: 'green !important',
			'& .MuiButton-label': {
				color: 'green',
			},
		},
	},
	internalItem: {
		'& .MuiBox-root': {
			'& > .MuiTypography-body1:first-child': {
				...theme.typography.overline,
			},
		},
		[theme.breakpoints.only('xs')]: {
			marginLeft: 0,
			marginRight: 0,
			flex: '1 0 var(--selected-item-size)',
		},
		/*
		[theme.breakpoints.up('sm')]: {
			'@supports (column-count: 2)': {
				'@supports (break-inside: avoid)': {
					breakInside: 'avoid',
					display: 'block',
					flex: 'none !important',
					marginTop: '0 !important',
					marginLeft: '0 !important',
					marginRight: '0 !important',
					marginBottom: 'var(--selected-item-margin) !important',
					width: 'auto'
				}
			},
			'&:nth-child(-n+2)': {
				marginTop: 0
			},
			'&:nth-child(2n)': {
				marginRight: 0,
				marginLeft: theme.spacing(4),
				flexBasis: `calc(var(--selected-item-size) - ${theme.spacing(4)}px)`
			},
			'&:nth-child(2n+1)': {
				marginLeft: 0,
				marginRight: theme.spacing(4),
				flexBasis: `calc(var(--selected-item-size) - ${theme.spacing(4)}px)`
			},
			'&:nth-last-child(-n+2)': {
				marginBottom: 0
			}
		}
		*/
	},
	inner: {
		[theme.breakpoints.up('sm')]: {
			position: 'relative',
			/*
			'&:before': {
				position: 'absolute',
				left: `calc(var(--selected-item-size) - 1px)`,
				width: `2px`,
				borderLeft: `2px solid gainsboro`,
				content: '""',
				display: 'block',
				top: 0,
				bottom: 0
			},
			'@supports (column-count: 2)': {
				'@supports (break-inside: avoid)': {
					'&:before': { content: 'none' },
					columnCount: 2,
					display: 'block',
					columnRule: '2px solid gainsboro',
					columnGap: theme.spacing(8)
				}
			}
			*/
		},
	},
});
