import React, { useState } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import RawBoxBlock from '../../raw/box/block';

const AnimatedBoxBlock = (props, ref) => {
	const [boxNode, setBoxNode] = useState();
	return <RawBoxBlock {...props} setBannerCallback={setBoxNode} ref={ref} />;
};
export default ThemeLoader(
	StyleLoader(
		AnimatedBoxBlock,
		makeStyles(theme => ({
			wrapper: {
				display: 'flex',
				alignItems: 'center',
				height: '100%',
				'& [class*=MuiBox-root-]': {
					width: '100%',
				},
			},
		}))
	)
);
