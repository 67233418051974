export default theme => ({
	root: {
		'& .MuiPaper-root': {
			background: 'transparent',
			'--image-width': '100px',
		},
		'& [class*=SwCard-mediaWrap]': {
			'& .MuiIcon-root': {
				color: '#333',
				fontSize: '5rem',
			},
			'& .MuiCardMedia-root': {
				border: '1px solid #eee',
				borderRadius: theme.shape.borderRadius,
				backgroundColor: '#fff',
				paddingTop: `calc(100% - 2px)`,
			},
		},
		'& .MuiCardContent-root .MuiButtonBase-root': {
			margin: theme.spacing(0.5, -0.5),
			padding: theme.spacing(0.5),
		},
		'& .MuiCardActionArea-root ~ .MuiCardContent-root [class*=SwCard-title]': {
			...theme.typography.caption,
		},
	},
});
