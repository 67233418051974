export default theme => ({
	root: {
		backgroundColor: 'rgba(255,255,255,0.7)',
		'& .MuiTypography-button': {
			color: '#de5f0e',
			fontWeight: 'bold',
		},
		'&:hover': {
			transition: '0.5s',
			backgroundColor: 'rgba(255,255,255,1)',
			'& .MuiTypography-button': {
				color: 'black',
			},
		},
		padding: '.5rem 1.5rem',
		borderRadius: '5px',
	},
});

		// '& button': {},