export default theme => ({
	root: {
		'& [class*=SwTile-squareInner-]': {
			top: theme.spacing(4),
			left: theme.spacing(4),
			right: theme.spacing(4),
			bottom: theme.spacing(4),
			overflow: 'hidden',
		},
	},
});
