export default theme => ({
	root: {
		border: '2px solid white',
		'& h3': {
			color: 'white',
			margin: '0.5rem',
		},
		'@media (min-width: 600px) and (max-width: 800px)': {
			'& h3': { fontSize: '1.9rem' },
			'& h6': { fontSize: '1rem' },
		},
	},
});
