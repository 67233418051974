const custom = theme => ({
	content: {
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
	},
	inner: {
		marginLeft: theme.spacing(4),
		marginRight: theme.spacing(4),
	},
	contentText: {
		marginLeft: 0,
		marginRight: 0,
	},
	square: {
		'&:before': {
			backgroundColor: 'rgba(255, 255, 255, 0)',
			backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))',
			transition: theme.transitions.create('background-color'),
		},
		'&:hover:before': {
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
		},
	},
	tileStripe: {
		...theme.typography.h6,
		textTransform: 'uppercase',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		top: 112,
		width: 200,
		right: 0,
		transformOrigin: '100% 100%',
		lineHeight: 1.5,
	},
});

export default custom;
