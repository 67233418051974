export default theme => ({
	initFadeIn: {
		'& > *:not(.not-animated)': {
			opacity: 0,
		},
	},
	runFadeIn: {
		'& > *:not(.not-animated)': {
			opacity: 1,
		},
	},
	initSlideUp: {
		'& > *:not(.not-animated)': {
			webkitTransform: 'translateY(30px)',
			transform: 'translateY(30px)',
		},
	},
	runSlideUp: {
		'& > *:not(.not-animated)': {
			webkitTransform: 'translateY(0px)',
			transform: 'translateY(0px)',
		},
	},
	initTransition: {
		'& > *:not(.not-animated)': {
			webkitTransition: 'all 0.6s ease-in-out',
			transition: 'all 0.6s ease-in-out',
		},
	},
});
