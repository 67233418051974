export default theme => ({
	root: {
		padding: theme.spacing(8),

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',

		'&:not([class*=SWRecordHeader-root])': {
			justifyContent: 'center',
		},

		'& .MuiButton-root, & [class*=MuiButton-root-]': {
			flex: '0 0 auto',
			alignSelf: 'flex-start',
		},
		'& .MuiButton-text, & [class*=MuiButton-text-]': {
			paddingLeft: 0,
			paddingRight: 0,
		},
		'& .MuiTypography-h6, & [class*=MuiTypography-h6-]': {},
		'& .MuiTypography-h5, & [class*=MuiTypography-h5-]': {},
		'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': { fontWeight: 'bold' },
		[theme.breakpoints.only('xs')]: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
			minHeight: '80vmin',
			'& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)': {
				width: '80%',
				marginLeft: 'auto',
				marginRight: 'auto',
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: 48,
			},
		},
		[theme.breakpoints.only('sm')]: {
			minHeight: '80vmin',
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(4),
			paddingRight: theme.spacing(4),
			'& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)': {
				width: '100%',
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: 64,
			},
		},
		[theme.breakpoints.only('md')]: {
			minHeight: '80vmin',
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(8),
			paddingRight: theme.spacing(8),
			'& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)': {
				width: '100%',
			},
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: '5rem',
			},
		},
		[theme.breakpoints.only('lg')]: {
			minHeight: '80vmin',
			// '& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)': {
			// 	maxWidth: 980,
			// },
			'& .MuiTypography-h1, & [class*=MuiTypography-h1-]': {
				fontSize: '5rem',
			},
		},
		[theme.breakpoints.only('xl')]: {
			minHeight: '80vmin',
			// '& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)': {
			// 	maxWidth: 1100,
			// },
		},
		'& > .MuiTypography-root, & > [class*=MuiTypography-root-]': {
			// '& ~ .MuiTypography-root:last-child, & ~ [class*=MuiTypography-root-]:last-child': {
			// 	[theme.breakpoints.up('lg')]: {
			// 		width: 'max-content',
			// 	},
			// },
			'& ~ .MuiButton-root:last-child, & ~ [class*=MuiButton-root-]:last-child': {
				marginTop: theme.spacing(4),
				position: 'relative',
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				marginLeft: -theme.spacing(2),
			},
		},
		'& [class*=SWRecordHeader-subTitle-][class*=MuiTypography-h1-]': {
			// At big sizes, h1 looks to have extra left spacing. Pull it back out.
			marginLeft: '-0.05em',
		},
		'& .transition-init': {
			webkitTransition: 'all 0.6s ease-in-out',
			transition: 'all 0.6s ease-in-out',
			opacity: 1,
			webkitTransform: 'translateY(30px)',
			transform: 'translateY(30px)',
		},
		'& .transition-fade': {
			opacity: 0,
		},
	},
	initFadeIn: {
		'& > *:not(.not-animated)': {
			opacity: 0,
			'& img': {
				opacity: 1,
			},
			'& [class*=SWRecordHeader-images-]': {
				opacity: 1,
			},
		},
		//  '& [class*=SWRecordHeader-inner-]': {
		//	  opacity: 1,
		//  },
	},
	runFadeIn: {
		'& > *:not(.not-animated)': {
			opacity: 1,
		},
	},
	initSlideUp: {
		'& > *:not(.not-animated)': {
			webkitTransform: 'translateY(30px)',
			transform: 'translateY(30px)',
			'& img': {
				webkitTransform: 'translateY(-30px)',
				transform: 'translateY(-30px)',
			},
			'& [class*=SWRecordHeader-images-]': {
				webkitTransform: 'unset',
				transform: 'unset',
			},
		},
	},
	runSlideUp: {
		'& > *:not(.not-animated)': {
			webkitTransform: 'translateY(0px)',
			transform: 'translateY(0px)',
			'& img': {
				webkitTransform: 'translateY(0px)',
				transform: 'translateY(0px)',
			},
		},
	},
	initTransition: {
		'& > *:not(.not-animated)': {
			webkitTransition: 'all 0.6s ease-in-out',
			transition: 'all 0.6s ease-in-out',
		},
	},
	headerStripe: {
		...theme.typography.h6,
		textTransform: 'uppercase',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
		top: 112,
		width: 200,
		right: 0,
		transformOrigin: '100% 100%',
		lineHeight: 1.5,
	},
});
