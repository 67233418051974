export default theme => ({
	root: {
		textAlign: 'center',
		flex: '1 1 auto',
		display: 'flex',
		flexDirection: 'column',
	},
	actionArea: {
		display: 'flex',
		flexDirection: 'column',
		height: 'auto',
		flex: '1 1 auto',
		alignItems: 'stretch',
	},
	cardContent: {
		display: 'flex',
		textAlign: 'left',
		flexDirection: 'column',
		justifyContent: 'center',
		flex: '1 1 35%',
		minHeight: '10vh',
		padding: theme.spacing(4),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(2),
		},
		'& .MuiTypography-subtitle1': {
			[theme.breakpoints.down('xs')]: {
				fontSize: '.8rem',
			},
		},
	},
	content: {},
	title: {
		margin: 'auto 0',
		marginTop: '0',
		paddingBottom: '0.35em',
		minHeight: `64px`,
		[theme.breakpoints.down('xs')]: {
			fontSize: '.9rem',
		},
	},
	caption: {
		...theme.typography.overline,
		lineHeight: '1.66',
		margin: '0.5rem 0 0.85rem',
		[theme.breakpoints.down('sm')]: {
			fontSize: '.8rem'
		}
	},
	avatarRoot: {
		[theme.breakpoints.down('xs')]: {
			width: '20px',
			height: '20px',
			fontSize: '.8rem',
		},
	},
});
