// Load it from the current package.
import { makeStyles } from '@material-ui/styles';
import React, { Component, Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { Registry } from '@sightworks/block';

function merge(startClasses = {}, ...rest) {
	const R = {};
	for (const [name, value] of Object.entries(startClasses)) {
		R[name] = new Set(value.split(/\s+/).filter(v => !!v));
	}
	for (let addClasses of rest) {
		for (const [name, value] of Object.entries(addClasses)) {
			R[name] = (R[name] || new Set()).add(value);
		}
	}
	const S = {};
	for (const [name, value] of Object.entries(R)) S[name] = [...value].join(' ');
	return S;
}

const useBackgroundBase = makeStyles(
	theme => ({
		root: {
			'--background-color': 'none',
			'--background-image': 'var(--landscape-background-image)',
			'--background-overlay': 'none',
			'--landscape-background-image': 'none',
			'--portrait-background-image': 'var(--landscape-background-image, none)',
			backgroundColor: 'var(--background-color, none)',
			backgroundImage: 'var(--background-overlay, none), var(--background-image, none)',
			backgroundSize: 'cover',
			backgroundPosition: '50% 50%',
			backgroundRepeat: 'no-repeat',
			'&.WithBackground-video': {
				position: 'relative',
				'& > *': {
					position: 'relative',
					zIndex: 1
				},
				'& > video': {
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 0,
					objectFit: 'cover',
					width: '100%',
					height: '100%'
				}
			},
			'@media (orientation: portrait)': {
				'--background-image': 'var(--portrait-background-image, var(--landscape-background-image))'
			}
		}
	}),
	{ name: 'WithBackground' }
);

const getBackgroundStyles = props => {
	let r = {};
	if (props.background.color) {
		r['--background-color'] = props.background.color;
	}
	if (props.background.image) {
		r['--landscape-background-image'] = `url(${props.background.image})`;
		if (props.background.portraitImage) {
			r['--portrait-background-image'] = `url(${props.background.portraitImage})`;
		}
	}
	if (props.background.overlay) {
		r['--background-overlay'] = props.background.overlay;
	}
	return r;
}

const bgs = new Map();
const useBackground = props => {
	let s = [ props.background.color, props.background.image, props.background.portraitImage, props.background.overlay ];
	if (!bgs.has(s.join(':'))) {
		bgs.set(s.join(':'), makeStyles(theme => ({
			root: getBackgroundStyles(props)
		})))
	}
	return bgs.get(s.join(':'))();
}

const useBackgroundDynamic = makeStyles(
	theme => ({
		root: props => {
			let r = {};
			if (props.background.color) {
				r['--background-color'] = props.background.color;
			}
			if (props.background.image) {
				r['--landscape-background-image'] = `url(${props.background.image})`;
				if (props.background.portraitImage) {
					r['--portrait-background-image'] = `url(${props.background.portraitImage})`;
				}
			}
			if (props.background.overlay) {
				r['--background-overlay'] = props.background.overlay;
			}
			return r;
		},
	}),
	{ name: 'BackgroundProps' }
);

let WithBackground = (props, ref) => {
	const baseStyles = useBackgroundBase(props);
	const styles = useBackground(props);
	const { classes, background, target, ...rest } = props;
	const video = useRef(null);

	let extra = { root: [] };
	if (props.background.image) extra.root.push('WithBackground-image');
	if (props.background.overlay) extra.root.push('WithBackground-overlay');
	if (props.background.color) extra.root.push('WithBackground-color');
	if (props.background.video) extra.root.push('WithBackground-video');

	extra.root = extra.root.join(' ');

	const mergedClasses = merge(classes || {}, baseStyles || {}, styles || {}, extra || {});
	rest.classes = mergedClasses;
	rest.ref = ref;
	// rest.style = { ...rest.style, ...getBackgroundStyles(props) };
	
	useEffect(() => {
		if (video.current) {
			const observer = new IntersectionObserver(entries => {
				entries.forEach(async entry => {
					let v = entry.isIntersecting ? 'play' : 'pause';
					try { await video.current[v](); } catch (e) { ; }
				});
			});
			observer.observe(video.current);
			// eslint-disable-next-line consistent-return
			return () => observer.unobserve(video.current);
		}

		return () => {};
	}, [ video.current ]);
	if (props.background.video) {
		return (
			<div className={mergedClasses.root}>
				<video src={props.background.video} autoPlay muted playsInline loop ref={video}/>
				{React.createElement(target, { ...rest, classes, ref })}
			</div>
		);
	}

	return React.createElement(target, rest);
};
WithBackground = React.forwardRef(WithBackground);

export default function BackgroundLoader(parent) {
	let target = parent;
	if (parent.length == 2) target = React.forwardRef(parent);

	const Loader = (props, ref) => {
		if ('background' in props) {
			return <WithBackground {...props} target={target} ref={ref} />;
		}
		return React.createElement(target, { ...props, ref });
	};

	Loader.displayName = `BackgroundLoader(${parent.displayName || parent.name || 'Anonymous'})`;
	return React.forwardRef(Loader);
}
