export default theme => ({
	root: {
		'&:hover': {
			color: 'black',
			transition: '0.5s',
		},
		borderRadius: '5px',
	},
});

		// '& .MuiTypography-button': {
		// 	color: '#de5f0e',
		// },