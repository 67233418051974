import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import clsx from 'clsx';

const CaseStudyTile = (
	{ classes, image, mainContent, company, secondaryContent, fullQuote, fullName, jobTitle, location, useContrast },
	ref
) => {
	return (
		<Box className={classes.root} ref={ref}>
			<img src={image} alt={`${company} Logo`} className={classes.image} />
			<Box className={classes.attribution}>
				<Typography variant="body1" className={classes.name}>
					{fullName}
				</Typography>
				<Typography variant="body2">{jobTitle}</Typography>
				<Typography variant="body2">{location}</Typography>
			</Box>
			<div
				className={clsx(classes.freeform, { [classes.contrast]: useContrast })}
				dangerouslySetInnerHTML={{ __html: mainContent }}
			/>
			<Box className={classes.quote}>
				<Typography variant="h5" className={classes.quoteText}>{fullQuote}</Typography>
			</Box>
			<div
				className={clsx(classes.freeform, { [classes.contrast]: useContrast })}
				dangerouslySetInnerHTML={{ __html: secondaryContent }}
			/>
		</Box>
	);
};

export default StyleLoader(
	CaseStudyTile,
	makeStyles(
		theme => ({
			root: {
				[theme.breakpoints.up('sm')]: {
					width: '80%',
					margin: '0 auto'
				}				
			},
			freeform: {
				margin: 0,
				'& a': {
					textDecoration: 'none',
					color: theme.palette.primary.light,
					transition: theme.transitions.create('color'),
					'&:hover': {
						color: theme.palette.primary.dark,
						textDecoration: 'underline',
					},
					'&.secondary': {
						color: theme.palette.secondary.main,
						'&:hover': {
							color: theme.palette.secondary.dark,
							textDecoration: 'underline',
						},
					},
				},
				'& > :first-child': { marginTop: 0 },
				'& > :last-child': { marginBottom: 0 },
				...theme.typography.body1,
				'& h1, & .h1': theme.typography.h1,
				'& h2, & .h2': theme.typography.h2,
				'& h3, & .h3': theme.typography.h3,
				'& h4, & .h4': theme.typography.h4,
				'& h5, & .h5': theme.typography.h5,
				'& h6, & .h6': theme.typography.h6,
				'& .body1': theme.typography.body1,
				'& .body2': theme.typography.body2,
				'& .caption': theme.typography.caption,
				'& .button': theme.typography.button,
				'& .subtitle1': theme.typography.subtitle1,
				'& .subtitle2': theme.typography.subtitle2,
				'& .overline': theme.typography.overline,
			},
			contrast: {
				color: theme.palette.primary.contrastText,
			},
			name: {
				fontWeight: 'bold',
			},
			attribution: {
				marginTop: '2rem',
				marginBottom: '3rem',
			},
			quote: {
				marginTop: '4rem',
				marginBottom: '4rem',
				paddingLeft: '2rem',
				borderLeft: '8px solid #de5f0e',
				[theme.breakpoints.up('sm')]: {
					margin: '4rem -10%'
				}				
			},
			quoteText: {
				'&:before': { content: 'open-quote' },
				'&:after': { content: 'close-quote' }
			},
			image: {
				maxWidth: '100%',
			},
		}),
		{ name: 'SWCaseStudyTile' }
	)
);
