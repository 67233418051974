import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader } from '@sightworks/theme';
import { Context } from '../../utils/query';

const CaseStudyTile = (
	{
		image = 'https://www.sightworks.com/admin/platform/plugins/com.sightworks.5.Channel.instance.sightworks/resources/images/map-eyeball.png',
		company = 'DigitalXE',
		shortQuote = '"Short Quote"',
		fullName = 'Full Name',
		jobTitle = 'Job Title',
		link = {
			href: 'https://www.sightworks.com'
		},
		classes,
	},
	ref
) => {
	const cx = useContext(Context);
	let href = link ? link.href : null;
	if (href && link.preserveQuery) href = cx.extend(href);

	return (
		<Box className={classes.root} ref={ref}>
			<img src={image} alt={company} />
			{/* <Typography variant="h4" color="primary">
				{company}
			</Typography> */}
			<div className={classes.content}>
				<Typography variant="h6" className={classes.quote}>
					{shortQuote}
				</Typography>
				<Typography variant="body2">
					{fullName}, {jobTitle}
				</Typography>
			</div>
			<Button color="primary" variant="outlined" 
				href={link ? href : void 0}
                target={link && link.openInNewWindow ? '_blank' : void 0}
                className={classes.caseStudyButton}
			>
				VIEW TESTIMONIAL
			</Button>
		</Box>
	);
};

export default StyleLoader(
	CaseStudyTile,
	makeStyles(
		theme => ({
			root: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				maxWidth: '100%',
				border: '1px solid rgba(0, 0, 0, 0.38)',
				borderRadius: 3,
				padding: '3rem 2rem',
				'& img': { maxWidth: '100%' },
			},
			quote: {
				marginBottom: '1rem',
				'&:before': { content: 'open-quote' },
				'&:after': { content: 'close-quote' },
			},
			content: {
				padding: '2rem',
				width: '100%',
				'@media (max-width: 600px)': { padding: '2rem 1.25rem' },
			},
			caseStudyButton: {
				'&:hover': {
					color: 'black',
					transition: '0.5s',
				},
				borderRadius: '5px',
				padding: '0.5rem 1.5rem',
			},
		}),
		{ name: 'SWCaseStudyTile' }
	)
);
