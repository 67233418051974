export default theme => ({
	...theme,
	palette: {
		primary: {
			main: theme.palette.secondary.main,
		},
		secondary: {
			main: theme.palette.primary.main,
		},
		type: 'dark',
	},
});
