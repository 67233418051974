export default theme => ({
	root: {
		position: 'relative',
		paddingTop: '100%',
		width: '100%',
		'& > *': {
			position: 'absolute',
			left: '0',
			top: '0',
			width: '100%',
			height: '100%',
		},
	},
});
