import React from 'react';
import ReactDOMServer from 'react-dom/server';

let baseCreate = React.createElement;
React.createElement = function(type, props, ...rest) {
	if (typeof type == 'string' && props && typeof props == 'object' && 'style' in props) {
		let e = ReactDOMServer.renderToStaticMarkup(baseCreate("div", { style: props.style }));
		if (e.indexOf('style=') != -1) {
			let f = e.indexOf('style="') + 'style="'.length;
			let g = e.indexOf('"', f);
			props['ie-style'] = e.substring(f, g);
			console.log(type, props['ie-style']);
		}
	}
	return baseCreate(type, props, ...rest);
}
