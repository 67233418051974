export default theme => ({
	inner: {
		justifyContent: 'center',
		//  gridTemplateColumns: 'repeat(auto-fit, calc(var(--selected-item-size) - var(--selected-item-margin))) !important'
	},
	root: {
		'& [class*=MuiPaper-root]': {
			width: '100%',
		},
		'& [class*=MuiCardMedia-root]': {
			width: '150px',
			height: '150px',
		},
		'& .MuiCardContent-root': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: theme.spacing(2),
			paddingRight: 0,
			alignItems: 'flex-start',
		},
		'& .MuiPaper-root': {
			boxShadow: 'unset',
			borderRadius: 0,
			backgroundColor: '#fff0',
		},
		'& .MuiButton-label': {
			justifyContent: 'left',
			//  color: theme.palette.secondary.main,
		},
		'& .MuiTypography-root': {
			//  marginLeft: '6px',
		},
		'& .MuiButtonBase-root': {
			margin: theme.spacing(0.5, -0.5),
			padding: theme.spacing(0.5),
		},
	},
});
