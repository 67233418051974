import yellow from '@material-ui/core/colors/yellow';
import HeaderBanner from './header-banner';

export default theme => {
	const lightYellow = yellow[100];
	const s = HeaderBanner(theme);
	['xs', 'sm', 'md', 'lg', 'xl'].forEach(v => {
		const b = theme.breakpoints.only(v);
		const u = s.root[b].minHeight;
		// eslint-disable-next-line prettier/prettier
		const [ , val, unit ] = /(\d+)(.*)/.exec(String(u));
		s.root[b].minHeight = val * 0.75 + (unit || 'px');
	});
	s.root['& .MuiTypography-h6, & [class*=MuiTypography-h6-]'].fontStyle = 'italic';
	s.root['& .MuiTypography-h6, & [class*=MuiTypography-h6-]'].color = lightYellow;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-]'].fontSize = theme.typography.h6.fontSize;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-]'].lineHeight = theme.typography.h6.lineHeight;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-]'].color = lightYellow;
	s.root['& .MuiTypography-h5, & [class*=MuiTypography-h5-]'].fontStyle = 'italic';
	s.root[theme.breakpoints.only('xl')][
		'& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)'
	].maxWidth = 1100;
	s.root['& .MuiTypography-h1, & [class*=MuiTypography-h1-]'] = {
		letterSpacing: '0.05em',
	};
	return s;
};
