import Page from './page/browser';
import FilterButton from './filter-button/browser';
import FilterBar from './filter-bar/browser';
import GalleryItem from './gallery-item/browser';
import GalleryItems from './gallery-items/browser';
import GalleryView from './gallery-view/browser';
import GalleryCurrentItem from './gallery-current/browser';
import GalleryContainer from './gallery-container/browser';
import GoogleSearch from './google-search/browser';
import GoogleResults from './google-results/browser';
import CalendlyWidget from './calendly-widget/browser';
import GoogleMaps from './google-maps/browser';
import CaseStudyTile from './case-study-tile/browser';
import CaseStudyContent from './case-study-content/browser';
// import AlertBanner from './alert-banner/browser';

export default {
	page: Page,
	'filter-button': FilterButton,
	'filter-bar': FilterBar,
	'gallery-item': GalleryItem,
	'gallery-items': GalleryItems,
	'gallery-view': GalleryView,
	'gallery-container': GalleryContainer,
	'google-search': GoogleSearch,
	'google-results': GoogleResults,
	'calendly-widget': CalendlyWidget,
	'google-maps': GoogleMaps,
	'gallery-current': GalleryCurrentItem,
	'case-study-tile': CaseStudyTile,
	'case-study-content': CaseStudyContent,
	//	'alert-banner': AlertBanner
};
