import ButtonBase from './button-base/browser';
import Container from './container/browser';
import FlexGrid from './flex-grid/browser';
import GridContainer from './grid-container/browser';
import Root from './root/browser';
import Box from './box/browser';
import Carousel from './carousel/browser';
import SharedBlockRoot from './shared-block-root/browser';
import AppBar from './app-bar/browser';
import Toolbar from './toolbar/browser';
import Drawer from './drawer/browser';
import NavDrawer from './nav-drawer/browser';
import NavSection from './nav-section/browser';
import NavItem from './nav-item/browser';
import SiteNavigation from './site-navigation/browser';
import Hidden from './hidden/browser';
import Calendar from './calendar/browser';
import Collapse from './collapse/browser';
import RecordHeader from './record-header/browser';
import Filter from './filter/browser';
import Dialog from './dialog/browser';
import Transition from './transition/browser';
import SquareBox from './square-box/browser';

export default {
	'button-base': ButtonBase,
	container: Container,
	'flex-grid': FlexGrid,
	'grid-container': GridContainer,
	box: Box,
	root: Root,
	carousel: Carousel,
	'shared-block-root': SharedBlockRoot,
	'app-bar': AppBar,
	toolbar: Toolbar,
	drawer: Drawer,
	'nav-drawer': NavDrawer,
	'site-navigation': SiteNavigation,
	'nav-section': NavSection,
	'nav-item': NavItem,
	hidden: Hidden,
	calendar: Calendar,
	collapse: Collapse,
	'record-header': RecordHeader,
	filter: Filter,
	dialog: Dialog,
	transition: Transition,
	'square-box': SquareBox,
};
