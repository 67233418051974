const custom = theme => ({
	mediaWrap: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	root: {
		width: '100%',
		'& .MuiCardActionArea-root': {
			height: '100%',
		},
		'& [class*=SwVerticalCard-media-]': {
			borderRadius: '100%',
			display: 'flex',
			margin: 'auto',
			width: 'var(--aspect-ratio)',
		},
		'& [class*=SwCard-title-]': {
			fontStyle: 'unset',
		},
	},
});

export default custom;
