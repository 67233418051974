export default theme => ({
	root: {
		[theme.breakpoints.down('md')]: {
			'& .MuiButton-label .MuiTypography-root.MuiTypography-button': {
				...theme.typography.h6,
				fontStyle: 'unset',
				textTransform: 'uppercase',
				// color: theme.palette.primary.main
			},
		},
	},
});
