export default theme => ({
	root: {
		padding: '0.5rem 3rem 0.5rem 0rem',
		'&:hover': {
			color: 'black',
			transition: '0.5s',
			paddingLeft: '1.5rem',
			paddingRight: '1.5rem',
		},
		borderRadius: '5px',
	},
});
		// backgroundColor: '#ffd1b5',
		// '& .MuiTypography-button': {
		// 	color: '#de5f0e',
		// },