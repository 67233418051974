import * as Registry from '../../registry';
import CssBaseline from '@material-ui/core/CssBaseline';
import React, { Component, Fragment } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { createMuiTheme, useTheme } from '@material-ui/core/styles';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
const Page = (props, ref) => {
	return (
		<>
			<CssBaseline/>
			{React.createElement(Registry.getBlock(props.root), { ...props.root, classes: props.classes, className: props.className, ref })}
		</>
	);
};

export default ThemeLoader(Page);
