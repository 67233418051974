import React, { useEffect, useState, useCallback } from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@sightworks/block/lib/components/DialogActions';
import DialogContent from '@sightworks/block/lib/components/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@sightworks/block/lib/components/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ReactGA from 'react-ga';

let open = url => {
	let left = (screen.width / 2) - (600 / 2);
	let top = (screen.height / 2) - (500 / 2);
	window.open(url, '_blank', `width=600,height=500,scrollbars=yes,chrome=yes,centerscreen,left=${left},top=${top}`);
}

let facebookShare = () => {
	if (typeof _gaq == 'object' && _gaq) _gaq.push([ '_trackSocial', 'facebook', 'share', location.href ]);
	open(`http://www.facebook.com/sharer.php?u=${encodeURIComponent(location.href)}&t=${encodeURIComponent(document.title)}`);
}

let twitterShare = () => {
	open(`https://twitter.com/share?url=${encodeURIComponent(location.href)}&text=${encodeURIComponent(document.title.replace(/\|/g, '-'))}`);
}

let linkedInShare = () => {
	open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(location.href)}&title=${encodeURIComponent(document.title)}`);
}

const ShareDialog = props => {
	let [ url, setUrl ] = useState(null);
	useEffect(() => {
		setUrl(location.href);
	}, []);

	useEffect(() => {
		if (props.open) {
			let path = [''].concat(window.location.pathname.split('/').filter(v => !!v)).concat('share').join('/');
			ReactGA.modalview(path);
		}
	}, [props.open]);

	return (
		<Dialog open={props.open} onClose={props.close} aria-labelledby='share-title' aria-describedby='share-description' maxWidth='sm' fullWidth className={props.classes.shareDialog}>
			<DialogTitle id='share-title' className={props.classes.shareTitle} onClose={props.close}>
				Share This
			</DialogTitle>
			<DialogContent className={props.classes.shareContent}>
				<Typography id='share-description' className={props.classes.shareDescription} variant="h4">Select a social network to share:</Typography>
				<div className={props.classes.shareButtons}>
					<IconButton className={props.classes.shareButton} onClick={facebookShare}>
						<FacebookIcon className={props.classes.icon}/>
					</IconButton>
					<IconButton className={props.classes.shareButton} onClick={twitterShare}>
						<TwitterIcon className={props.classes.icon}/>
					</IconButton>
					<IconButton className={props.classes.shareButton} onClick={linkedInShare}>
						<LinkedInIcon className={props.classes.icon}/>
					</IconButton>
				</div>
				<TextField readOnly variant='filled' label='Link to this page' value={url} className={props.classes.link}/>
			</DialogContent>
			<DialogActions onClose={props.close}/>
		</Dialog>
	);
}

const FabBlock = (props, ref) => {
	let [ open, setOpen ] = useState(false);
	let toggle = useCallback(() => setOpen(!open), [open]);

	return (
		<>
			<div className={props.classes.root}>
				<div className={clsx('mui-fixed', props.classes.fabWrapper)}>
					<Fab color="primary" aria-label="Share" className={props.classes.fab} onClick={toggle}>
						<Icon>share</Icon>
					</Fab>
				</div>
			</div>
			<ShareDialog {...props} open={open} close={toggle}/>
		</>
	)
};

export default ThemeLoader(
	StyleLoader(
		FabBlock, 
		makeStyles(theme => ({
			fabWrapper: {
				position: 'fixed',
				bottom: theme.spacing(2),
				right: theme.spacing(2),
				zIndex: 10
			},
			fab: {},
			root: {
				height: 56 + theme.spacing(4)
			},
			icon: {
				fontSize: '4rem'
			},
			shareDialog: {},
			shareTitle: {},
			shareContent: {},
			shareDescription: {
				textAlign: 'center'
			},
			shareButtons: {
				margin: theme.spacing(2, 0),
				justifyContent: 'center',
				display: 'flex',
				flexDirection: 'row'
			},
			shareButton: {
				margin: theme.spacing(0, 0.5),
				flex: '0 0'
			},
			link: {
				display: 'flex'
			}
		}), { name: 'Fab' })
	)
);
