/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import { makeStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import getChildren from '../../utils/children';

const SquareBoxBlock = (props, ref) => {
	const { box, noCollapseMargin, minHeight, classes, content } = props;

	return (
		<div className={classes.wrapper}>
			<div className={classes.content}>
				<Box
					{...box}
					className={clsx(classes.root, classes.inner, {
						[classes.noCollapsedMargin]: noCollapseMargin,
					})}
					{...(minHeight ? { style: { minHeight } } : {})}
					ref={ref}
				>
					{getChildren(content)}
				</Box>
			</div>
		</div>
	);
};
export default ThemeLoader(
	StyleLoader(
		SquareBoxBlock,
		makeStyles(theme => ({
			noCollapsedMargin: {
				overflow: 'hidden',
			},
			wrapper: {
				display: 'block',
				position: 'relative',
				width: '100%',
				paddingTop: '100%',
			},
			content: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
			},
			inner: {
				height: '100%',
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		}))
	)
);
