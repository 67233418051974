import SmallHeaderBanner from './small-header-banner';

export default theme => {
	const inheritedStyles = SmallHeaderBanner(theme);
	const newStyles = {
		root: {
			padding: `0 !important`,
			minHeight: '0px !important',
		},
		title: {
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(1),
			},
		},
		inner: {
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
				marginBottom: theme.spacing(4),
			},
		},
		text: {
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				alignItems: 'center',
			},
		},
		images: {
			marginRight: theme.spacing(4),
			flex: 'unset',
			[theme.breakpoints.down('sm')]: {
				flex: 'unset',
				maxWidth: '100%',
				width: '100%',
				marginRight: theme.spacing(0),
				marginBottom: theme.spacing(4),
			},
			'& [class*=SwRawImage-root-]': {
				width: 'auto',
				maxHeight: '650px',
			},
		},
		subTitle: {
			[theme.breakpoints.down('sm')]: {
				marginBottom: theme.spacing(4),
			},
		},
		withImages: {
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
	};

	const mergedStyles = { ...inheritedStyles, ...newStyles };

	return mergedStyles;
};
