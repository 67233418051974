export default theme => ({
  root: {
    paddingTop: 'var(--margin-small)',
    paddingBottom: 'var(--margin-large)',
    borderBottom: `1px solid #ddd`,
    '& .MuiPaper-root.MuiCard-root': {
      margin: theme.spacing(1)
    }
  }
});
