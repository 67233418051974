import React, { useContext, useCallback, useState, useEffect, useRef, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import { StyleLoader, ThemeLoader } from '@sightworks/theme';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Grow from '@material-ui/core/Grow';
import Zoom from '@material-ui/core/Zoom';
import { Context, WithButton } from '../filter/context';
import getChildren from '../../utils/children';
import useBreakpoint from '../../utils/useBreakpoint';

const useTransition = () => {
	const [transitioning, setTransitioning] = useState(false);
	const isTransitioning = useCallback(() => setTransitioning(true), []);
	const doneTransitioning = useCallback(() => setTransitioning(false), []);
	return [transitioning, isTransitioning, doneTransitioning];
};

const Dummy = () => {};
const InternalItemSpec = `SwGridContainerInternalItem`;

const SwGridContainer = (props, ref) => {
	const bp = useBreakpoint();
	let filterContext = useContext(Context);
	let usedContext = filterContext;

	const node = useRef(null);
	const spacer = useRef(null);
	const wasOpen = useRef(filterContext ? filterContext.open : false);
	const [itemInfo, setItemInfo] = useState({ multiple: true, style: {}, outerStyle: {} });
	const [transitioning, setIsTransitioning, setDoneTransitioning] = useTransition();
	const [filterBar, setFilterBar] = useState(null);

	const itemsYPos = [];

	useEffect(() => {
		const resize = () =>
			requestAnimationFrame(() => {
				const items = spacer.current.querySelectorAll(`.${InternalItemSpec}`);
				const itemWidth = items[0].getBoundingClientRect().width;
				const fitsThree = items[0].getBoundingClientRect().left < items[2].getBoundingClientRect().left;

				setItemInfo({
					style: {
						'--column-size': `${itemWidth}px`,
					},
					outerStyle: {
						minHeight: filterBar ? `calc(var(--selected-item-margin) + ${filterBar.offsetHeight}px)` : void 0,
					},
					multiple: fitsThree,
				});
				filterContext && filterContext.setColumnSize(`${itemWidth}px`);
			});

		window.addEventListener('resize', resize);
		resize();
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, [props.classes.internalItem, filterBar, filterContext ? filterContext.setColumnSize : Dummy]);

	const findButton = content =>
		content && content.find
			? content.find(node => node && (node.type == 'filter-button' || (node.content && findButton(node.content))))
			: null;
	let hasButton = false;
	let Wrap = Fragment;
	if (findButton(props.content)) {
		hasButton = true;
		filterContext = null;
		Wrap = WithButton;
	} else if (filterContext && filterContext.hasButton) {
		hasButton = true;
		filterContext = null;
	}
	useEffect(() => {
		if (filterContext && filterContext.open != wasOpen.current) {
			setIsTransitioning();
			wasOpen.current = filterContext.open;
		}
	}, [filterContext, filterContext ? filterContext.open : false]);

	useEffect(() => {
		if (usedContext && props.content.filter(v => v.type == 'filter-bar').length) {
			console.log(`Filter in drawer: `, itemInfo.multiple == false);
			usedContext.setFilterInDrawer(!itemInfo.multiple);
		}
	}, [usedContext, itemInfo.multiple, props.content]);

	// console.log(props.id, ': ', usedContext ? usedContext.filterInDrawer : null);

	return (
		<Wrap>
			<Box
				className={clsx(props.classes.root, props.classes[bp], {
					[props.classes.marginOutside]: props.marginOutside,
					[props.classes.flex]: props.flex,
					[props.classes.xsContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.xs) != -1,
					[props.classes.smContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.sm) != -1,
					[props.classes.mdContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.md) != -1,
					[props.classes.lgContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.lg) != -1,
					[props.classes.xlContent]: props.flex || ['min-content', 'max-content'].indexOf(props.size.xl) != -1,
					[props.classes.xsFill]: props.size.xs == '100%',
					[props.classes.smFill]: props.size.sm == '100%',
					[props.classes.mdFill]: props.size.md == '100%',
					[props.classes.lgFill]: props.size.lg == '100%',
					[props.classes.xlFill]: props.size.xl == '100%',
					[props.classes.verticalCenterContent]: props.verticalCenter,
					[props.classes.filterInDrawer]: usedContext && usedContext.filterInDrawer
				})}
				data-filter-in-drawer={usedContext ? usedContext.filterInDrawer : false}
				style={{
					'--item-size-xs': props.size.xs,
					'--item-size-sm': props.size.sm,
					'--item-size-md': props.size.md,
					'--item-size-lg': props.size.lg,
					'--item-size-xl': props.size.xl,
					'--item-margin-xs': props.margin.xs,
					'--item-margin-sm': props.margin.sm,
					'--item-margin-md': props.margin.md,
					'--item-margin-lg': props.margin.lg,
					'--item-margin-xl': props.margin.xl,
					minHeight: props.minHeight,
					...itemInfo.outerStyle,
				}}
			>
				<div
					className={clsx(props.classes.inner, props.classes.columnSpacer, {
						[props.classes.gridStretch]: props.fillGrid,
						[props.classes.nowrap]: props.nowrap,
					})}
					ref={spacer}
					role="presentation"
				>
					<div
						className={clsx(props.classes.item, props.classes.internalItem, props.classes.itemSpacer, InternalItemSpec, {
							[props.classes.fillGrid]: props.fillGrid,
							[props.classes.fillItemSpace]: props.fillItemSpace,
						})}
					/>
					<div
						className={clsx(props.classes.item, props.classes.internalItem, props.classes.itemSpacer, InternalItemSpec, {
							[props.classes.fillGrid]: props.fillGrid,
							[props.classes.fillItemSpace]: props.fillItemSpace,
						})}
					/>
					<div
						className={clsx(props.classes.item, props.classes.internalItem, props.classes.itemSpacer, InternalItemSpec, {
							[props.classes.fillGrid]: props.fillGrid,
							[props.classes.fillItemSpace]: props.fillItemSpace,
						})}
					/>
				</div>
					<div
						ref={node}
						onTransitionEnd={setDoneTransitioning}
						className={clsx(props.classes.inner, {
							[props.classes.alignVertically]: props.centerVertically,
							[props.classes.gridStretch]: props.fillGrid,
							[props.classes.withFilter]: !hasButton, // !!filterContext,
							[props.classes.filterOpen]: filterContext && itemInfo.multiple && wasOpen.current, // filterContext.open,
							[props.classes.transitioning]: transitioning,
							[props.classes.multiple]: itemInfo.multiple,
							[props.classes.nowrap]: props.nowrap,
						})}
						style={itemInfo.style}
					>
						{filterContext
							? getChildren(props.content.filter(node => node.type == 'filter-bar'), (node, child) => {
								let Wrap = Fragment;
								let wrapProps = {};
								let ref = null;
								if (!itemInfo.multiple) {
									Wrap = Drawer;
									wrapProps = {
										anchor: 'left',
										open: filterContext.open,
										onClose: filterContext.toggle,
										classes: {
											root: props.classes.drawer,
											paper: props.classes.drawerPaper,
										},
									};
								} else {
									Wrap = 'div';
									wrapProps = {
										className: clsx(
											props.classes.item,
											props.classes.internalItem,
											InternalItemSpec,
											// props.classes.alignVertically,
											props.classes.filterBar,
											{
												[props.classes.fillGrid]: props.fillGrid,
												[props.classes.fillItemSpace]: props.fillItemSpace,
											}
										),
									};
									ref = setFilterBar;
								}

								return (
									<Wrap {...wrapProps} key={child.id}>
										{!itemInfo.multiple && (
											<div className={props.classes.drawerHeader}>
												<Typography variant="body1" className={props.classes.drawerText}>
													{child.drawerTitle || 'Filter'}
												</Typography>
												<IconButton onClick={filterContext.toggle} color="inherit">
													<Icon>close</Icon>
												</IconButton>
											</div>
										)}
										<div className={props.classes.filterBarInner} ref={ref}>
											{React.cloneElement(node, { inDrawer: !!(usedContext && usedContext.filterInDrawer) })}
										</div>
									</Wrap>
								);
							})
							: null}
						{getChildren(props.content.filter(node => node.type != 'filter-bar'), (node, child, index) => {
							const gridItem = useRef(null);
							const [toggle, setToggle] = useState(false);
							let Animate = Fragment;
							let animateProps = {};
							if (
								node.props.type !== 'filter-button' &&
								node.props.type !== 'button' &&
								props.animationType !== 'none'
							) {
								const modules = {
									fade: Fade,
									zoom: Zoom,
									grow: Grow
								};
								Animate = modules[props.animationType];
								if (Animate) {
									animateProps = {
										in: toggle,
										timeout: 1000,
									};
								} else {
									Animate = Fragment;
								}
							}

							useEffect(() => {
								if (props.animationType === 'none') return;
								const el = gridItem.current;
								const maxDelay = 500;
								const handleToggle = columnIndex => {
									const timeout = (maxDelay / itemsYPos.length) * columnIndex;
									setTimeout(() => {
										setToggle(true);
									}, timeout);
								};

								if (itemsYPos.length < 1 || el.offsetLeft > itemsYPos[itemsYPos.length - 1])
									itemsYPos.push(el.offsetLeft);

								const observer = new IntersectionObserver(entries => {
									entries.forEach(entry => {
										if (entry.isIntersecting) handleToggle(itemsYPos.indexOf(el.offsetLeft));
									});
								});
								observer.observe(el);
								// eslint-disable-next-line consistent-return
								return () => observer.unobserve(el);
							}, []);

							return (
								<Animate {...animateProps} key={child.id}>
									<div style={{ visibility: void 0 }}
										className={clsx(
											props.classes.item,
											// props.classes.alignVertically,
											props.classes.internalItem,
											InternalItemSpec,
											{
												[props.classes.fillGrid]: props.fillGrid,
												[props.classes.filterButton]: child.type == 'filter-button',
												[props.classes.fillItemSpace]: props.fillItemSpace,
											}
										)}
										ref={gridItem}
									>
										{node}
									</div>
								</Animate>
							);
						})}
				</div>
			</Box>
		</Wrap>
	);
};

export default ThemeLoader(
	StyleLoader(
		SwGridContainer,
		makeStyles(
			theme => ({
				root: {
					'--selected-item-size': '100%',
					'--selected-item-margin': '0',
					'--column-size': 'var(--selected-item-size)',
					position: 'relative',
				},
				flex: {
					'& $item': {
						maxWidth: 'var(--selected-item-size)'
					}
				},
				item: {
					flex: '1 0 calc(var(--selected-item-size) - var(--selected-item-margin))',
					margin: 'calc(var(--selected-item-margin) * 0.5)',
					'&$itemSpacer': {
						marginTop: 0,
						marginBottom: 0,
					},
					'$xs$xsContent > $inner &, $sm$smContent > $inner &, $md$mdContent > $inner &, $lg$lgContent > $inner &, $xl$xlContent > $inner &': {
						flex: '1 0 var(--selected-item-size)',
					},
					'@supports (display: grid)': {
						'$xs:not($xsContent) > $inner &, $sm:not($smContent) > $inner &, $md:not($mdContent) > $inner &, $lg:not($lgContent) > $inner &, $xl:not($xlContent) > $inner &': {
							margin: 0,
							flex: 'auto',
						},
					},
				},
				internalItem: {},
				fillGrid: {
					display: 'flex',
					flexDirection: 'column',
					'& > *': {
						flex: '1 1 auto',
					},
				},
				fillItemSpace: {
					height: '100%',
					width: '100%',
				},
				alignVertically: {
					'& [class*=SwGridContainer-item-]': {
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					},
				},
				filterBar: {
					position: 'absolute',
					top: 'calc(var(--selected-item-margin) * 0.5)',
					left: 'calc(var(--selected-item-margin) * 0.5)',
					width: 'var(--column-size)',
					// bottom: 'calc(var(--selected-item-margin) * 0.5)',
					transform: 'translateX(calc(0px - (1.5 * var(--selected-item-margin)) - var(--column-size)))',
					transition: theme.transitions.create('transform'),
				},
				filterBarInner: {
					visibility: 'hidden',
				},
				marginOutside: {},
				inner: {
					'& [class*=SwGridContainer-item-]': {
						'& .MuiButton-root:last-child': {
							marginRight: theme.spacing(1),
						},
					},
					padding: 0,
					'$marginOutside &': {
						padding: 'calc(0.5 * var(--selected-item-margin))',
						'&$columnSpacer': {
							paddingTop: 0,
							paddingBottom: 0,
						},
					},
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					'@supports (display: grid)': {
						'$xs:not($xsContent) > &, $sm:not($smContent) > &, $md:not($mdContent) > &, $lg:not($lgContent) > &, $xl:not($xlContent) > &': {
							display: 'grid',
							gridTemplateColumns:
								'repeat(auto-fill, minmax(calc(var(--selected-item-size) - var(--selected-item-margin)), 1fr))',
							gridAutoFlow: 'row',
							gridColumnGap: 'var(--selected-item-margin)',
							gridRowGap: 'var(--selected-item-margin)',
							'& $item': {
								margin: 0,
							},
							'&$transitioning': {
								gridTemplateColumns: 'repeat(auto-fill, var(--column-size))',
							},
							'&$columnSpacer': {
								gridRowGap: 0,
							},
						},
					},
					'&$withFilter': {
						position: 'relative',
						transition: theme.transitions.create('padding-left'),
						'&$filterOpen': {
							paddingLeft: 'var(--column-size, 250px)',
							'$marginOutside &': {
								paddingLeft: 'calc(var(--column-size, 250px) + (1.5 * var(--selected-item-margin)))',
							},
						},
					},
					'&$transitioning $item': {
						flexGrow: '0 !important',
						flexBasls: 'var(--column-size)',
					},
				},
				transitioning: {
					'& $filterBarInner': {
						visibility: 'visible',
					},
				},
				withFilter: {},
				filterOpen: {
					'& $filterBar': {
						transform: 'translateX(0px)',
					},
					'& $filterBarInner': {
						visibility: 'visible',
					},
				},
				gridStretch: {
					'@supports (display: grid)': {
						alignItems: 'stretch',
					},
				},
				xs: {
					'--selected-item-size': 'var(--item-size-xs)',
					'--selected-item-margin': 'var(--item-margin-xs)',
				},
				sm: {
					'--selected-item-size': 'var(--item-size-sm)',
					'--selected-item-margin': 'var(--item-margin-sm)',
				},
				md: {
					'--selected-item-size': 'var(--item-size-md)',
					'--selected-item-margin': 'var(--item-margin-md)',
				},
				lg: {
					'--selected-item-size': 'var(--item-size-lg)',
					'--selected-item-margin': 'var(--item-margin-lg)',
				},
				xl: {
					'--selected-item-size': 'var(--item-size-xl)',
					'--selected-item-margin': 'var(--item-margin-xl)',
				},
				xsContent: {},
				smContent: {},
				mdContent: {},
				lgContent: {},
				xlContent: {},
				xsFill: {},
				smFill: {},
				mdFill: {},
				lgFill: {},
				xlFill: {},
				multiple: {},
				nowrap: {
					whiteSpace: 'nowrap',
					flexWrap: 'nowrap',
					overflow: 'auto',
				},
				filterButton: {
					position: 'sticky',
					left: 'calc(var(--selected-item-margin) * -0.5)',
					margin: 'calc(var(--selected-item-margin) * -0.5)',
					marginRight: 'calc(var(--selected-item-margin) * 0.5)',
					padding: 'var(--selected-item-margin)',
					backgroundColor: theme.palette.background.default,
					zIndex: 1,
				},
				itemSpacer: {},
				columnSpacer: {},
				drawer: {
					'& $filterBarInner': {
						visibility: 'visible',
						padding: theme.spacing(3.5),
						paddingTop: 0,
					},
				},
				drawerPaper: {
					maxWidth: '400px',
					width: '100%',
				},
				drawerHeader: {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					padding: theme.spacing(2),
					flex: '0 0 auto',
				},
				drawerText: {
					flex: '1 1 auto',
					padding: theme.spacing(1.5),
				},
				verticalCenterContent: {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center'
				},
				filterInDrawer: {}
			}),
			{ name: 'SwGridContainer' }
		)
	)
);
