export default theme => ({
	root: {
		[`&.${theme.globalStyles.base.marginTop}`]: {
			marginTop: 0,
			paddingTop: 'var(--computed-top-margin)',
		},
		[`&.${theme.globalStyles.base.marginBottom}`]: {
			marginBottom: 0,
			paddingBottom: 'var(--computed-bottom-margin)',
		},
	},
});
