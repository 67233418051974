import HeaderBanner from './header-banner';

export default theme => {
	const s = HeaderBanner(theme);
	['xs', 'sm', 'md', 'lg', 'xl'].forEach(v => {
		const b = theme.breakpoints.only(v);
		const u = s.root[b].minHeight;
		const [, val, unit] = /(\d+)(.*)/.exec(String(u));
		s.root[b].minHeight = val * 0.75 + (unit || 'px');
	});
	s.root[theme.breakpoints.only('xl')][
		'& .MuiTypography-root:not($headerStripe), & [class*=MuiTypography-root-]:not($headerStripe)'
	].maxWidth = 1600;

	return s;
};
