import { fade } from '@material-ui/core/styles/colorManipulator';

export default theme => ({
	root: {
		border: 0,
		background: fade(theme.palette.common[theme.palette.type === 'dark' ? 'black' : 'white'], 0.25),
		'& [class*=MuiTypography-button]': {
			textTransform: 'none',
		},
		'&:hover': {
			border: 0,
		},
	},
	outlinedPrimary: {
		color: theme.palette.primary[theme.palette.type === 'dark' ? 'light' : 'dark'],
	},
	outlinedSecondary: {
		color: theme.palette.secondary[theme.palette.type === 'dark' ? 'light' : 'dark'],
	},
});
